import Dashboard from '@/views/Dashboard.vue'
import Loading from '@/views/Loading.vue'
import { createRouter, createWebHistory, Router } from 'vue-router'
import RouterModel from './models/RouterModel'
import { Module, RoleLevel } from './models/static/Authorizer'

const router: Router = createRouter({
  history: sessionStorage.getItem('stagingVersion') ? createWebHistory(sessionStorage.getItem('stagingVersion') as string) : createWebHistory(),
  routes: [
    {
      path: '/signin',
      name: 'Sign In',
      component: () => import(/* webpackChunkName: "signin" */ './views/auth/SignIn.vue'),
      meta: {
        requiresTenant: false,
        allowSignedOutAccess: true,
        allowSignedInAccess: false,
        requiredModule: null,
        minimumRoleLevel: RoleLevel.None,
        title: 'Sign In'
      }
    },
    {
      path: '/forgotpassword',
      name: 'Forgot Password',
      component: () => import(/* webpackChunkName: "forgotpassword" */ './views/auth/ForgotPassword.vue'),
      meta: {
        requiresTenant: false,
        allowSignedOutAccess: true,
        allowSignedInAccess: false,
        requiredModule: null,
        minimumRoleLevel: RoleLevel.None,
        title: 'Forgot Password'
      }
    },
    {
      path: '/resetpassword',
      name: 'Reset Password',
      component: () => import(/* webpackChunkName: "resetpassword" */ './views/auth/ResetPassword.vue'),
      meta: {
        requiresTenant: false,
        allowSignedOutAccess: true,
        allowSignedInAccess: false,
        requiredModule: null,
        minimumRoleLevel: RoleLevel.None,
        title: 'Reset Password'
      }
    },
    {
      path: '/newuserpassword',
      name: 'New User Password',
      component: () => import(/* webpackChunkName: "newuserpassword" */ './views/auth/NewUserPassword.vue'),
      meta: {
        requiresTenant: false,
        allowSignedOutAccess: true,
        allowSignedInAccess: true,
        requiredModule: null,
        minimumRoleLevel: RoleLevel.None,
        title: 'Create New Password'
      }
    },
    {
      path: '/ui',
      name: 'ui',
      component: () => import(/* webpackChunkName: "ui" */ './views/UI.vue'),
      meta: {
        requiresTenant: false,
        allowSignedOutAccess: true,
        allowSignedInAccess: true,
        requiredModule: null,
        minimumRoleLevel: RoleLevel.SystemAdmin,
        title: 'UI Components'
      }
    },
    {
      path: '/loading',
      name: 'Loading',
      component: Loading,
      meta: {
        requiresTenant: false,
        allowSignedOutAccess: true,
        allowSignedInAccess: true,
        requiredModule: null,
        minimumRoleLevel: RoleLevel.None,
        title: 'Loading'
      }
    },
    {
      path: '/verify-email/:verificationCode',
      name: 'Verify Email',
      component: () => import(/* webpackChunkName: "verifyemail" */ './views/auth/VerifyEmail.vue'),
      props: true,
      meta: {
        requiresTenant: false,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: null,
        minimumRoleLevel: RoleLevel.None,
        title: 'Verify Email'
      }
    },
    {
      path: '/account/',
      name: 'My Account',
      component: () => import(/* webpackChunkName: "account" */ './views/accounts/Account.vue'),
      meta: {
        requiresTenant: false,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: null,
        minimumRoleLevel: RoleLevel.None,
        title: 'My Account'
      }
    },
    {
      path: '/system-administration/',
      name: 'System Administration',
      component: () => import(/* webpackChunkName: "account" */ './views/system/SystemAdministration.vue'),
      meta: {
        requiresTenant: false,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: null,
        minimumRoleLevel: RoleLevel.SystemAdmin,
        title: 'System Administration'
      }
    },
    {
      path: '/company-information/:tenant',
      name: 'Company Information',
      component: () => import(/* webpackChunkName: "account" */ './views/system/CompanyInformation.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.TenantAdmin,
        title: 'Company Information'
      }
    },
    {
      path: '/units-and-pressure-base/:tenant',
      name: 'Units and Pressure Base',
      component: () => import(/* webpackChunkName: "account" */ './views/system/UnitsAndPressureBase.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.TenantAdmin,
        title: 'Units and Pressure Base'
      }
    },
    {
      path: '/organizations/:tenant',
      name: 'Organizations',
      component: () => import(/* webpackChunkName: "account" */ './views/system/OrganizationsAdministration.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.TenantAdmin,
        title: 'Organizations'
      }
    },
    {
      path: '/lookup-values/:tenant',
      name: 'Lookup Values',
      component: () => import(/* webpackChunkName: "account" */ './views/system/LookupValues.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.TenantAdmin,
        title: 'Lookup Values'
      }
    },
    {
      path: '/dashboard/:tenant?',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Dashboard'
      }
    },
    {
      path: '/wells/:tenant',
      name: 'Wells',
      component: () => import(/* webpackChunkName: "wells" */ './views/wells/Wells.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Wells'
      }
    },
    {
      path: '/fields/:tenant',
      name: 'Fields',
      component: () => import(/* webpackChunkName: "fields" */ './views/fields/Fields.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Fields'
      }
    },
    {
      path: '/production/:tenant',
      name: 'Production',
      component: () => import(/* webpackChunkName: "production" */ './views/production/Production.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Production'
      }
    },
    {
      path: '/pipelines/:tenant',
      name: 'Pipelines',
      component: () => import(/* webpackChunkName: "pipelines" */ './views/pipelines/Pipelines.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Pipelines'
      }
    },
    {
      path: '/pipeline-details/:id/:tenant',
      name: 'Pipeline Details',
      component: () => import(/* webpackChunkName: "pipelinedetails" */ './views/pipelines/PipelineDetails.vue'),
      props: true,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Pipeline Details'
      }
    },
    {
      path: '/facilities/:tenant',
      name: 'Facilities',
      component: () => import(/* webpackChunkName: "facilities" */ './views/facilities/Facilities.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Facilities'
      }
    },
    {
      path: '/facility-details/:id/:tenant',
      name: 'Facility Details',
      component: () => import(/* webpackChunkName: "facilitydetails" */ './views/facilities/FacilityDetails.vue'),
      props: true,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Facility Details'
      }
    },
    {
      path: '/jobs/:tenant/:tab?',
      name: 'Jobs',
      component: () => import(/* webpackChunkName: "jobs" */ './views/jobs/Jobs.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Write,
        title: 'Jobs'
      }
    },
    {
      path: '/meters/:tenant',
      name: 'Meters',
      component: () => import(/* webpackChunkName: "meters" */ './views/meters/Meters.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Meters'
      }
    },
    {
      path: '/meter-details/:id/:tenant',
      name: 'Meter Details',
      component: () => import(/* webpackChunkName: "meterdetails" */ './views/meters/MeterDetails.vue'),
      props: true,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Meter Details'
      }
    },
    {
      path: '/well-details/:id/:tenant',
      name: 'Well Details',
      component: () => import(/* webpackChunkName: "welldetails" */ './views/wells/WellDetails.vue'),
      props: true,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Well Details'
      }
    },
    {
      path: '/organization-details/:id/:tenant',
      name: 'Organization Details',
      component: () => import(/* webpackChunkName: "organizationdetails" */ './views/system/OrganizationDetails.vue'),
      props: true,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Organization Details'
      }
    },
    {
      path: '/reporting-units/:tenant',
      name: 'Reporting Units',
      component: () => import(/* webpackChunkName: "reportingunits" */ './views/reporting-unit/ReportingUnits.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Reporting Units'
      }
    },
    {
      path: '/cumulative-unit-gas-production/:id/:formulaTypeId/:versionId?/:tenant',
      name: 'Cumulative Unit Gas Production',
      component: () => import('./views/reporting-unit/FormulaReportingUnitDetails.vue'),
      props: true,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Cumulative Unit Gas Production'
      }
    },
    {
      path: '/water-allocation/:id/:formulaTypeId/:versionId?/:tenant',
      name: 'Water Allocation',
      component: () => import('./views/reporting-unit/FormulaReportingUnitDetails.vue'),
      props: true,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Water Allocation'
      }
    },
    {
      path: '/gas-roll-up-allocation/:id/:formulaTypeId/:versionId?/:tenant',
      name: 'Gas Roll-up Allocation',
      component: () => import('./views/reporting-unit/FormulaReportingUnitDetails.vue'),
      props: true,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Gas Roll-up Allocation'
      }
    },
    {
      path: '/gas-sales-allocation/:id/:formulaTypeId/:versionId?/:tenant',
      name: 'Gas Sales Allocation',
      component: () => import('./views/reporting-unit/FormulaReportingUnitSalesDetails.vue'),
      props: true,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Gas Sales Allocation'
      }
    },
    {
      path: '/plant-allocation/:id/:formulaTypeId/:versionId?/:tenant',
      name: 'Plant Allocation',
      component: () => import('./views/reporting-unit/FormulaReportingUnitSalesDetails.vue'),
      props: true,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Plant Allocation'
      }
    },
    {
      path: '/wellbore-details/:id/:tenant',
      name: 'Wellbore Details',
      component: () => import(/* webpackChunkName: "wellboredetails" */ './views/wellbores/WellboreDetails.vue'),
      props: true,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Wellbore Details'
      }
    },
    {
      path: '/reporting-unit-details/:id/:tenant',
      name: 'Reporting Unit Details',
      component: () => import(/* webpackChunkName: "reportingunitdetails" */ './views/reporting-unit/ReportingUnitDetails.vue'),
      props: true,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Reporting Unit Details'
      }
    },
    {
      path: '/gas-analysis/:tenant',
      name: 'Gas Analysis',
      component: () => import(/* webpackChunkName: "gas-analysis" */ './views/gas-analysis/AnalysisGas.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Gas Analysis'
      }
    },
    {
      path: '/allocations/:tenant',
      name: 'Allocations',
      component: () => import('./views/allocations/Allocations.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Allocations'
      }
    },
    {
      path: '/allocations/:id/:tenant',
      name: 'Allocation Details',
      component: () => import('./views/allocations/AllocationDetails.vue'),
      props: true,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Allocation Details'
      }
    },
    {
      path: '/pressure-readings/:tenant',
      name: 'Pressure Readings',
      component: () => import('./views/measurements/PressureReadings.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Pressure Readings'
      }
    },
    {
      path: '/asset-map/:tenant',
      name: 'Asset Map',
      component: () => import(/* webpackChunkName: "assetmap" */ './views/AssetMap.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Asset Map'
      }
    },
    {
      path: '/reports/:tenant/:tab?',
      name: 'Reports',
      component: () => import(/* webpackChunkName: "assetmap" */ './views/reports/Reports.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Reports'
      }
    },
    {
      path: '/unauthorized',
      name: 'Unauthorized',
      component: () => import(/* webpackChunkName: "unauthorized" */ './views/Unauthorized.vue'),
      meta: {
        requiresTenant: false,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: null,
        minimumRoleLevel: RoleLevel.None,
        title: 'Unauthorized'
      }
    },
    {
      path: '/',
      redirect: { name: 'Dashboard' }
    },
    {
      path: '/:catchAll(.*)',
      name: 'Not Found',
      component: () => import(/* webpackChunkName: "notfound" */ './views/NotFound.vue'),
      meta: {
        requiresTenant: false,
        allowSignedOutAccess: true,
        allowSignedInAccess: true,
        requiredModule: null,
        minimumRoleLevel: RoleLevel.None,
        title: 'Page Not Found'
      }
    },
    {
      path: '/error',
      name: 'Unexpected Error',
      component: () => import(/* webpackChunkName: "notfound" */ './views/ErrorPage.vue'),
      meta: {
        requiresTenant: false,
        allowSignedOutAccess: true,
        allowSignedInAccess: true,
        requiredModule: null,
        minimumRoleLevel: RoleLevel.None,
        title: 'Unexpected Error'
      }
    },
    {
      path: '/maintenance',
      name: 'Maintenance',
      component: () => import(/* webpackChunkName: "notfound" */ './views/MaintenancePage.vue'),
      meta: {
        requiresTenant: false,
        allowSignedOutAccess: true,
        allowSignedInAccess: true,
        requiredModule: null,
        minimumRoleLevel: RoleLevel.None,
        title: 'Maintenance'
      }
    },
    {
      path: '/switchover',
      name: 'Switchover',
      component: () => import(/* webpackChunkName: "notfound" */ './views/SwitchoverPage.vue'),
      meta: {
        requiresTenant: false,
        allowSignedOutAccess: true,
        allowSignedInAccess: true,
        requiredModule: null,
        minimumRoleLevel: RoleLevel.None,
        title: 'Switchover'
      }
    },
    {
      path: '/field-observations/:tenant/:assetTab?/:observationTab?',
      name: 'Field Observations',
      component: () => import(/* webpackChunkName: "field-observations" */ './views/field-observations/FieldObservations.vue'),
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Write,
        title: 'Field Observations'
      }
    },
    {
      path: '/string-production-details/:id/:tenant',
      name: 'Production String Details',
      component: () => import(/* webpackChunkName: "string-production-details" */ './views/string-production/StringProductionDetails.vue'),
      props: true,
      meta: {
        requiresTenant: true,
        allowSignedOutAccess: false,
        allowSignedInAccess: true,
        requiredModule: Module.AssetManagement,
        minimumRoleLevel: RoleLevel.Read,
        title: 'Production String Details'
      }
    }
  ]
})

router.beforeResolve((to, from, next) => {
  const routerModel = new RouterModel(to, from, next)
  routerModel.handleRoute()
})

router.afterEach((to) => {
  let title = 'Bensis'
  if (to.meta.title !== undefined) {
    title = title + ' - ' + to.meta.title
  }
  document.title = title
})

export default router
