import { FieldObservation, FieldObservationParams } from '@/models/base/FieldObservation'
import ID from '@/models/base/ID'
import StringProduction from '@/models/StringProduction'
import Well from '@/models/Well'

export const wellObservationApolloModel = (properties: string) =>
  ` id
    observationDate
    casingStatus
    leakStatus
    wellId
    well { id name permitNumber apiNumber }
    stringProductionId
    stringProduction { id referenceDescription referenceDescriptionAlternative }
    mpscProjectRu
    ${properties}
    standardComments { id name }
    fieldComment
    createdUser
    modifiedUser
    createdDate
    modifiedDate
  `

export interface WellObservation {
  well?: Well
  stringProduction?: StringProduction
  mpscProjectRu?: string
  standardComments: ID[]
}

export interface WellObservationParams extends FieldObservationParams {
  wellId?: ID | null
  stringProductionId?: ID | null
  standardComments?: {id: ID, name: string}[]
}

export class WellObservation extends FieldObservation {
  private _wellId: ID | null = null
  private _stringProductionId: ID | null = null

  constructor (fieldObservation?: WellObservationParams) {
    super({ ...fieldObservation, standardCommentIds: fieldObservation?.standardComments?.map(item => item.id) ?? [] })

    if (fieldObservation !== undefined) {
      if (fieldObservation.wellId !== undefined) {
        this._wellId = fieldObservation.wellId
      }

      if (fieldObservation.stringProductionId !== undefined) {
        this._stringProductionId = fieldObservation.stringProductionId
      }
    }
  }

  get wellId (): ID | null {
    return this._wellId
  }

  set wellId (value: ID | null) {
    value = this.convertUndefinedToNull(value)
    this._wellId = value
  }

  get stringProductionId (): ID | null {
    return this._stringProductionId
  }

  set stringProductionId (value: ID | null) {
    value = this.convertUndefinedToNull(value)
    this._stringProductionId = value
  }

  getApolloFormattedModel () {
    return {
      ...super.getApolloFormattedModel(),
      wellId: this.wellId,
      stringProductionId: this.stringProductionId
    }
  }
}
